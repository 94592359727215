var w = window.innerWidth;
if (w >= 1024) {
  $( ".slider" ).prepend( "<div class='first-slide'><img src='{{file_dest}}/first_slide.png' alt='Obrazek przedtawiający jak działają zamówienia'></div>" );
};

$(document).ready(function(){
  $('.slider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    nextArrow: '<i class="fa fa-chevron-right"></i>',
    prevArrow: '<i class="fa fa-chevron-left"></i>'
  });
});
