function initMap() {

  // ALL pins
  var locations = [
  ['Korespondencja Mont-Pal', 53.539072, 14.537571],
  ['Miejsce działalności Mont-Pal', 53.5536119, 14.5534641]
  ];

  var infowindow = new google.maps.InfoWindow();  //info box
  var center_map = {lat: 53.5457962, lng: 14.5483383};
  var mapDiv = document.getElementById('map');
  var map = new google.maps.Map(mapDiv, {
    center: center_map,
    zoom: 13,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    disableDoubleClickZoom: true,
    zoomControl: true
  });

  var iconBase = '{{file_dest}}/';
  for (var i = 0; i < locations.length; i++) {

    //Add markers
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(locations[i][1], locations[i][2]),
      map: map,
      icon: iconBase + 'marker.png'
    });

    //Add info box
    google.maps.event.addListener(marker, 'click', (function(marker, i) {
      return function() {
        infowindow.setContent(locations[i][0]);
        infowindow.open(map, marker);
      }
    })(marker, i));
  }
}


$( document ).ready(function() {

  $('.footer-content > button').click(function(){
    $('.map').removeClass('map_off');
  })

  $('.map i').click(function(){
    $('.map').addClass('map_off');
  })

});
