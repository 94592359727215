$( document ).ready(function() {
  $('.menu_button').on('click', function(){
    $('.toogle_menu').addClass('active');
  })
  //if click menu items box displey: none
  $('.menu_items, .toogle_menu i').on('click', function(){
    $('.toogle_menu').removeClass('active');
  })

  //scroll # links
  $('a[href^="#"]').click(function() {
  $('html,body').animate({ scrollTop: $(this.hash).offset().top}, 500);
  return false;
  e.preventDefault();

  })
});
