$( document ).ready(function() {
  $('.description button').on('click', function(){
    $(this).parents( ".description" ).addClass("deactive");
    $(this).parents( ".description" ).next().addClass("active");
  })

  $('.more button').on('click', function(){
    $(this).parents( ".more" ).removeClass("active");
    $(this).parents( ".more" ).prev().removeClass("deactive");
  })
});
